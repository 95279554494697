<template>
  <div class="list-my-course">
    <div class="list-my-course-leftSide">
      <div class="list-my-course__photo">
        <Cover :cover="data && data.cover ? data.cover : {}" />
      </div>
    </div>

    <div class="list-my-course-rightSide">
      <div class="list-my-course__rating" v-if="getShowRating">
        <Rating :value="data.rating" />
      </div>
      <div class="list-my-course__title">
        <TitleCourse :value="data.title" />
      </div>

      <div class="list-my-course__progress">
        <div class="list-my-course__progress--percent">
          <span class="list-my-course__progress--percent__title"
            >{{ $t('my_courses.progresso') }} {{ data.percentage_complete ? data.percentage_complete : 0 }}%</span
          >
          <span class="list-my-course__progress--percent__icon">
            <IconSax primary size="1rem" name="medal-star" :type="completed ? 'bold' : 'outline'" />
          </span>
        </div>
        <ProgressBar :percentage="data.percentage_complete ? data.percentage_complete : 0 " />
        <template v-if="!getIsMobile">
             <div class="list-my-course__cert" v-if="completed">
              <!-- /course/149/certificate/me -->
                <div @click="getCertifyUniq(data.id)">{{ $t('my_courses.certificado') }}</div>
             </div>
          </template>
      </div>
      
      <!-- MOBILE -->
      <template>
        <div class="list-my-course__boxGroup">
          <template v-if="getIsMobile">
             <div class="list-my-course__cert" v-if="completed">
                 <div @click="getCertifyUniq(data.id)">{{ $t('my_courses.certificado') }}</div>
             </div>
          </template>
          <div class="list-my-course__button">
            <ButtonPlay
              rounded
              size="smm"
              :type="completed ? 'normal' : 'outline'"
              @click="openCourse()"
            >
              <span class="adminBar__actions--text">
                {{ completed ? $t('my_courses.concluido') :  $t('my_courses.acessar') }}
              </span>
              <IconSax
                size="1rem"
                :type="completed ? 'bold' : 'outline'"
                :name="completed ? 'tick-circle' : 'arrow-circle-right'"
              />
            </ButtonPlay>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  methods: {
    openCourse() {
      this.$emit('open');
    },
    getCertifyUniq() {
      this.$router.push({ path: "/certificates"});
    },
  },
  computed: {
    ...mapGetters({
      getMe: "auth/getMe",
      getSettings: "config/getSettings",
      getModuleViewMode: "config/getModuleViewMode",
      getCategoryViewMode: "config/getCategoryViewMode",
      getMovieCoverMode: "config/getMovieCoverMode",
      getHideDisabledCourses: "config/getHideDisabledCourses",
      getIsMobile: "layout/getIsMobile",
      getModeCarousel: "config/getModeCarousel",
      getShowRating: "config/getShowRating",
      getIsLoading: "config/getIsLoading",
      getAllowRequest: "config/getAllowRequest",
      getHomeData: "home/getHomeData",
      getAllCategories: "home/getAllCategories",
      getModalInfoCourse: "home/getModalInfoCourse",
      getModalBuyCourse: "home/getModalBuyCourse",
      getIsAdmin: "config/getIsAdmin",
    }),
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    completed: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Cover: () => import("@/components/common/cards/cover/Cover.vue"),
    Rating: () => import("@/components/common/cards/rating/Rating.vue"),
    TitleCourse: () => import("@/components/common/title/Course.vue"),
    ProgressBar: () =>
      import("@/components/common/progressBar/ProgressBar.vue"),
  },
};
</script>

<style lang="scss" scoped>
/* Mobile first */
.list-my-course {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 174px;
  gap: 0px;
  padding-right: 0px;
  border-radius: 8px;
  background: var(--my-courses-background-color);
  border: var(--my-courses-border);
  cursor: pointer;

  /* Desktop */
  @media screen and (min-width: 768px) {
    padding-right: 0px;
    height: 72px;
    padding-right: 20px;
    max-width: 100%;
    align-items: center;
    flex-wrap: nowrap;
  }

  &:hover {
    background: var(--my-courses-hover-background-color);
    // box-shadow: var(--my-courses-box-shadow);
    ::v-deep .custom-button {
      // background: var(--my-courses-hover-background-color);
    }
  }

  &-leftSide {
    display: flex;
    width: 63.22px;
    align-self: stretch;

    /* Desktop */
    @media screen and (min-width: 768px) {
      display: flex;
      flex-direction: row;
      align-items: center;
      // flex-basis: 60%;
      gap: 12px;
    }
  }

  &-rightSide {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 12px;
    width: calc(100% - 63.22px);

    /* Desktop */
    @media screen and (min-width: 768px) {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
      justify-content: flex-start;
      width: 100%;
      gap: 0px;
      padding: 0px 0px;
          margin-left: 15px;
    }
  }

  &__rating {
    margin-bottom: 8px;

    /* Desktop */
    @media screen and (min-width: 768px) {
      margin-bottom: 0px;
      display: flex;
      align-items: center;
      height: 100%;
      flex-basis: 37px;
      justify-content: center;
      margin: 12px 12px 12px 0px;
    }

    ::v-deep .commonCardsRating span {
      @extend .body-semibold-10;
    }
  }

  &__progress {
    display: flex;
    flex-direction: column;
    gap: 4px;
    height: 24px;
    margin-bottom: 12px;

    /* Desktop */
    @media screen and (min-width: 768px) {
      flex-basis: 220px;
      height: 100%;
      margin-bottom: 0px;
      justify-content: space-around;
      padding: 12px 0px;
      margin: 0px 40px;
    }

    &--percent {
      display: flex;
      justify-content: space-between;

      &__title {
        color: var(--neutral-gray-400, #9e9e9e);
        @extend .body-semibold-12;
        display: flex;
        align-items: center;
      }

      &__icon {
        display: flex;
        align-items: center;
        ::v-deep svg {
          width: 16px;
          height: 16px;
        }
        ::v-deep svg path {
          fill: var(--maincolor);
        }
      }
    }
  }

  &__boxGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__cert {
    color: var(--maincolor);
    @extend .body-regular-12;
  }

  &__button {
    
    
    /* Desktop */
    @media screen and (min-width: 768px) {
      flex-basis: 107px;
    }
  }

  &__photo {
    ::v-deep .commonCardsCover__box {
      width: 100%;
      position: relative;
      overflow: hidden;
      min-width: 63px !important;
      min-height: 173px !important;
      border-radius: 8px 0px 0px 8px;

      /* Desktop */
      @media screen and (min-width: 768px) {
        min-height: 72px !important;
      }
    }

    ::v-deep .commonCardsCover__box--image {
      border-radius: 8px 0px 0px 8px;
      height: 160%;
    }

    ::v-deep .commonCardsCover__box.poster::before {
      content: "";
      display: block;
      padding-top: 112.33% !important;
    }
  }

  &__title {
    height: 40px;
    align-self: stretch;
    margin-bottom: 12px;

    /* Desktop */
    @media screen and (min-width: 768px) {
      margin-bottom: 0px;
      display: flex;
      align-items: center;
      height: 100%;
      flex-basis: 385px;
    }

    ::v-deep .commomTitleCourse {
      margin-bottom: 0px !important;
    }

    ::v-deep .commomTitleCourse__title {
      color: var(--opposite-color);
      @extend .body-semibold-16;
    }
  }
}
</style>
